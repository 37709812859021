<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense class="text-h6">
        <v-toolbar-title>Agregar video</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <br />
      <v-container>
         <v-text-field
          outlined
          dense
          v-model="nombre"
          label="Nombre"
          :disabled="loading"
          :error-messages="errorsNombre.nombre"
          @input="$v.nombre.$touch()"
          @blur="$v.nombre.$touch()"
        ></v-text-field>
      
        <v-file-input
          v-model="archivo"
          placeholder="Seleccionar video"
          prepend-icon="mdi-video"
          outlined
          dense
          :error-messages="error"
          :disabled="loading"
          @change="validarArchivo"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label>{{ text }}</v-chip>
          </template>
        </v-file-input>
        <v-col v-if="!terminado" class="subtitle-1 text-center"
          >Subiendo video {{ progreso + " %" }}</v-col
        >
        <v-col v-if="procesando" class="subtitle-1 text-center"
          >Procesando video..</v-col
        >

        <v-progress-linear
          v-if="!terminado"
          :value="progreso"
          :max="100"
          show-progress
          animate
          rounded
          height="12"
        ></v-progress-linear>
        <v-progress-linear
          v-if="procesando"
          :value="progreso"
          :max="100"
          indeterminate=""
          animate
          rounded
          height="12"
        ></v-progress-linear>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          small
          :loading="loading"
          :disabled="!archivo || !esValido || nombre==''"
          @click="calcularTiempo()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { agregarVideoService } from "./videos.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name:"editor-video",
  props:{
    mostrar: Boolean,
    idArea: String,
    idSubarea: String
  },
  mixins: [validationMixin],
  computed: {
     errorsNombre() {
      const errors = {
        nombre: [],
      };

      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required &&
        errors.nombre.push("Campo requerido.");

      return errors;
    },
     

    esValido() {
      if (this.errorsNombre.nombre.length ) return false;
      return true;
    },
  },
  validations: {
    nombre: { required },
    
  },
  data: () => ({
    loading: false,
    procesando: false,
    archivo: null,
    terminado: true,
    progreso: 0,
    error: null,
    tiempo: null,
    nombre:'',
    descripcion:''
  }),
  watch: {
    'tiempo': function (value) {
      if (value) this.agregarVideo();
    }
  },
  created() {},
  methods: {
    validarArchivo() {
      this.error = null;
      if (this.archivo) {
        let arreglo = this.archivo.name.split(".");
        let ext = arreglo[arreglo.length - 1];
        if (ext != "mp4" && ext != "MP4")
          this.error = "Debe seleccionar un archivo mp4";
        if (this.archivo.size > 100*1024*1024) this.error = "Tamaño máximo de 100MB";
      }
    },
    async agregarVideo() {
      try {
        this.loading = true;
        const tiempo = this.calcularMiutos();
        const video = new FormData();
        video.append('video', this.archivo);
        video.append("tiempo", tiempo);
        video.append("nombre", this.nombre)
        
        const uploadHandler = {
          onUploadProgress: (progressEvent) => {
            this.progreso = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (this.progreso >= 98) {
              this.terminado = true;
              this.procesando = true;
            }
          }    
        };
        const ids = {idArea: this.idArea, idSubarea: this.idSubarea};
        
        const serverResponse = await agregarVideoService(video, uploadHandler, ids);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.terminado = true;
          this.procesando = false;
          this.$emit("cancelar");
        } else {
          this.$emit("videoAgregado", serverResponse.video);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.procesando = false;
        this.$appErrorMessage();
      }
    },
    calcularTiempo(){
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = ()=> {this.tiempo = video.duration };
      video.onloadend = ()=> URL.revokeObjectURL(video.src);
      video.src = URL.createObjectURL(this.archivo);   

    },
    calcularMiutos(){
      const minutos = Math.trunc(this.tiempo / 60);
      let segundos = Math.round(this.tiempo- (minutos * 60));
      if(segundos <= 9)
        segundos = segundos.toString().padStart(2,'0');
      const tiempo = `${minutos}:${segundos}`
      return tiempo;
    },
    
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
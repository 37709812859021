import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.escuelaTienePermiso)?_c('loading-video'):_c(VCard,{attrs:{"flat":""}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"secondary","dark":"","flat":""}},[_c(VSpacer),_vm._v("VIDEOS"),_c(VSpacer)],1),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c('selector-areas',{attrs:{"areas":_vm.areas,"menu":_vm.esAdmin},on:{"agregarArea":function($event){_vm.abrirEditorArea = true},"modificarArea":_vm.modificarAreaSeleccionada,"change":function($event){_vm.limpiarPagination(), _vm.getSubareas(_vm.areaSeleccionada)}},model:{value:(_vm.areaSeleccionada),callback:function ($$v) {_vm.areaSeleccionada=$$v},expression:"areaSeleccionada"}})],1)],1),_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c('div',{staticClass:"d-flex"},[_c('selector-areas',{attrs:{"areas":_vm.subareas,"menu":_vm.esAdmin,"etiqueta":'Subárea'},on:{"agregarArea":function($event){_vm.abrirEditorSubarea = true},"modificarArea":_vm.modificarSubareaSeleccionada,"change":function($event){_vm.limpiarPagination(), _vm.getVideos()}},model:{value:(_vm.subareaSeleccionada),callback:function ($$v) {_vm.subareaSeleccionada=$$v},expression:"subareaSeleccionada"}}),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"color":"primary","fab":"","small":"","disabled":!_vm.areas.length || !_vm.subareas.length || !_vm.esAdmin},on:{"click":function($event){_vm.abrirEditorVideo = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-video-plus")])],1)]}}])},[_c('span',[_vm._v("Agregar video")])])],1)])],1)],1),(!_vm.videos.length && !_vm.loading)?_c(VCardText,[_vm._v("No se han subido videos.")]):(_vm.loading)?[_c('loading-video')]:(_vm.videos.length > 0)?[_c(VContainer,{attrs:{"fluid":""}},[_c(VCol,{attrs:{"md":"12","sm":"12"}},[_c('tablaVideos',{attrs:{"loading":_vm.loading,"datos":_vm.videos,"pagination":_vm.pagination,"page":_vm.pagination.page,"itemsPerPage":10,"totalItems":_vm.pagination.itemsLength,"headers":_vm.headers,"hideSearch":""},on:{"update-page":_vm.updatePageTable,"pagina-change":_vm.paginaCambio},scopedSlots:_vm._u([{key:"item-video",fn:function(ref){
var value = ref.value;
return [_c('router-link',{staticClass:"link",attrs:{"to":("video/" + (value.area) + "/" + (value.subarea) + "/" + (value._id))}},[_vm._v(" "+_vm._s(value.nombre)+" ")])]}},{key:"item-usuario",fn:function(ref){
var value = ref.value;
return [_c('h5',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item-tiempo",fn:function(ref){
var value = ref.value;
return [_c('h5',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(value)+" minutos")])]}},{key:"item-edicion",fn:function(ref){
var value = ref.value;
return [_c(VMenu,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"small":"","icon":"","color":"primary","disabled":!_vm.esAdmin || _vm.loading}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"grey"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.moverVideo(value._id)}}},[_c(VListItemTitle,[_vm._v("Mover el video de área")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c(VDivider,{staticClass:"mx-3"})],1)],1)]}}])})],1)],1)]:_vm._e()],2)],1),(_vm.abrirEditorArea)?_c('editor-area-video',{attrs:{"mostrar":_vm.abrirEditorArea,"area":_vm.areaParaModificar},on:{"cancelar":_vm.cancelarEditarArea,"areaGuardada":_vm.areaGuardada}}):_vm._e(),(_vm.abrirEditorSubarea)?_c('editor-subarea-video',{attrs:{"mostrar":_vm.abrirEditorSubarea,"subarea":_vm.subareaParaModificar,"idArea":_vm.areaSeleccionada},on:{"cancelar":_vm.cancelarEditarsubarea,"subareaGuardada":_vm.subareaGuardada}}):_vm._e(),(_vm.abrirEditorVideo)?_c('editorVideo',{attrs:{"mostrar":_vm.abrirEditorVideo,"idSubarea":_vm.subareaSeleccionada,"idArea":_vm.areaSeleccionada},on:{"cancelar":function($event){_vm.abrirEditorVideo = false},"videoAgregado":_vm.updateVideos}}):_vm._e(),(_vm.mover)?_c('mover-video',{attrs:{"mostrar":_vm.mover,"video":_vm.videoSeleccionado,"area":_vm.areaSeleccionada,"subarea":_vm.subareaSeleccionada},on:{"cancelar":function($event){_vm.mover = false},"videoActualizado":_vm.updateVideos}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
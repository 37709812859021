<template>
  <v-dialog v-model="mostrar" :max-width="600" persistent>

    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense class="text-h6">
        <v-toolbar-title>Mover video</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <br />
      <v-container>
     <v-row>
        <v-col md="6" sm="12">
          <v-container>
            <selector-areas
              :areas="areas"
                      
              v-model="areaSeleccionada"
              
              @change="getSubareas(areaSeleccionada)"
            
            />
          </v-container>
        </v-col>
        <v-col md="6" sm="12">
          <v-container>
            <div class="d-flex">
            <selector-areas
              :areas="subareas"
            
              v-model="subareaSeleccionada"
              :etiqueta="'Subárea'"

                       
            />              
            </div>
          </v-container>
        </v-col>            
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined small :disabled="loading" @click="$emit('cancelar')">Cancelar</v-btn>
        <v-btn color="primary" small :loading="loading" :disabled="subareaSeleccionada == '' || loading || subareaSeleccionada==subarea" @click="moverVideo()"> Mover</v-btn>
      </v-card-actions>
      </v-container>
    </v-card>

  </v-dialog>
</template>

<script>
import { getAreasService, getSubareasService, moverVideoService } from "./videos.service";
import selectorAreas from "../widgets/selectorAreas.vue";

export default {
  name:"moverVideo",
  components:{ selectorAreas },
  props:{
    mostrar: { type: Boolean, default: false },
    video: { type: String, default: () => ''},
    area: { type: String, default:()=> ''},
    subarea: { type: String, default:()=> ''}
  },
  
  computed: {

  
  },
  data: () => ({
    loading: false, 
    areas:[],
    subareas:[],
    areaSeleccionada:"",
    subareaSeleccionada:""
  }),
 
  async created() {
    this.areaSeleccionada = this.area;
    this.getAreas()
    await this.getSubareas(this.areaSeleccionada)
    this.subareaSeleccionada = this.subarea;
  },

  methods: {
    async moverVideo(){
     try {
        this.loading = true;
        const serverResponse = await moverVideoService(this.areaSeleccionada, this.subareaSeleccionada, this.video );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("videoActualizado");
          this.$emit("cancelar");
        }
      } catch (error) {
        //console.log(error);
        this.loading = false;
        this.$appErrorMessage();
      }

    },
    async getAreas() {
      this.loading = true;
      try {
        const serverResponse = await getAreasService();
        this.loading = false;

        this.$validateResponse(serverResponse);
        
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.areas.length) this.areas = serverResponse.areas;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async getSubareas(areaSeleccionada) {

      this.loading = true;
      try {
        const serverResponse = await getSubareasService(areaSeleccionada);
        this.loading = false;
  
        this.$validateResponse(serverResponse);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          
          if(serverResponse.subareas.length>0){
            this.subareas = serverResponse.subareas;
            this.subareaSeleccionada=this.subareas[0]._id;
          }
          else this.subareas = [];
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    
  }
};
</script>

<style scoped>

</style>
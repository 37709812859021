<template>
  <v-container>
    <loading-video v-if="!escuelaTienePermiso" />
    <v-card v-else flat>
      <v-toolbar color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer>VIDEOS<v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col md="6" sm="12">
            <v-container>
              <selector-areas
                :areas="areas"
                :menu="esAdmin"
                v-model="areaSeleccionada"
                @agregarArea="abrirEditorArea = true"
                @modificarArea="modificarAreaSeleccionada"
                @change="limpiarPagination(), getSubareas(areaSeleccionada)"
              />
            </v-container>
          </v-col>
          <v-col md="6" sm="12">
            <v-container>
              <div class="d-flex">
                <selector-areas
                  :areas="subareas"
                  :menu="esAdmin"
                  v-model="subareaSeleccionada"
                  :etiqueta="'Subárea'"
                  @agregarArea="abrirEditorSubarea = true"
                  @modificarArea="modificarSubareaSeleccionada"
                  @change="limpiarPagination(), getVideos()"
                />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      fab
                      small
                      style="margin-left: 10px"
                      :disabled="!areas.length || !subareas.length || !esAdmin"
                      @click="abrirEditorVideo = true"
                      ><v-icon>mdi-video-plus</v-icon></v-btn
                    >
                  </template>
                  <span>Agregar video</span>
                </v-tooltip>
              </div>
            </v-container>
          </v-col>
        </v-row>

        <v-card-text v-if="!videos.length && !loading"
          >No se han subido videos.</v-card-text
        >

        <template v-else-if="loading">
          <loading-video />
        </template>
        <template v-else-if="videos.length > 0">
          <v-container fluid>
            <v-col md="12" sm="12">
              <tablaVideos
                :loading="loading"
                :datos="videos"
                :pagination="pagination"
                :page="pagination.page"
                :itemsPerPage="10"
                :totalItems="pagination.itemsLength"
                :headers="headers"
                hideSearch
                @update-page="updatePageTable"
                @pagina-change="paginaCambio"
              >
                <template #item-video="{ value }">
                  <router-link
                    :to="`video/${value.area}/${value.subarea}/${value._id}`"
                    class="link"
                  >
                    {{ value.nombre }}
                  </router-link>
                </template>
                <template #item-usuario="{ value }">
                  <h5 style="text-align: center">
                    {{ value }}
                  </h5>
                </template>
                <template #item-tiempo="{ value }">
                  <h5 style="text-align: center">{{ value }} minutos</h5>
                </template>
                <template #item-edicion="{value}">
                  <v-menu left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="margin-left: 10px"
                        icon
                        color="primary"
                        :disabled="!esAdmin || loading"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="grey">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="moverVideo(value._id)">
                        <v-list-item-title
                          >Mover el video de área</v-list-item-title
                        >
                        <v-list-item-icon
                          ><v-icon small>mdi-pencil</v-icon></v-list-item-icon
                        >
                      </v-list-item>
                      <v-divider class="mx-3"></v-divider>
                    </v-list>
                  </v-menu>
                </template>
              </tablaVideos>
            </v-col>
          </v-container>
        </template>
      </v-container>
    </v-card>

    <editor-area-video
      v-if="abrirEditorArea"
      :mostrar="abrirEditorArea"
      :area="areaParaModificar"
      @cancelar="cancelarEditarArea"
      @areaGuardada="areaGuardada"
    />

    <editor-subarea-video
      v-if="abrirEditorSubarea"
      :mostrar="abrirEditorSubarea"
      :subarea="subareaParaModificar"
      :idArea="areaSeleccionada"
      @cancelar="cancelarEditarsubarea"
      @subareaGuardada="subareaGuardada"
    />

    <editorVideo
      v-if="abrirEditorVideo"
      :mostrar="abrirEditorVideo"
      :idSubarea="subareaSeleccionada"
      :idArea="areaSeleccionada"
      @cancelar="abrirEditorVideo = false"
      @videoAgregado="updateVideos"
    />

    <mover-video
      v-if="mover"
      :mostrar="mover"
      :video="videoSeleccionado"
      :area="areaSeleccionada"
      :subarea="subareaSeleccionada"
      @cancelar="mover = false"
      @videoActualizado="updateVideos"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import tablaVideos from "../widgets/datatable.vue";
import selectorAreas from "../widgets/selectorAreas.vue";
import EditorAreaVideo from "./editorAreaVideo.vue";
import EditorSubareaVideo from "./editorSubareaVideo.vue";
import EditorVideo from "./agregarVideo.vue";
import loadingVideo from "./loadingVideos.vue";
import moverVideo from "./moverVideoArea.vue";

import {
  getAreasService,
  getSubareasService,
  getVideosService,
} from "./videos.service";

export default {
  components: {
    selectorAreas,
    EditorAreaVideo,
    EditorSubareaVideo,
    EditorVideo,
    loadingVideo,
    tablaVideos,
    moverVideo,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),

    esAdmin() {
      return (
        this.app == "campus" || this.role == "admin" || this.role == "profesor"
      );
    },
  },
  watch: {
    sessionToken(value) {
      if (value) {
        if (this.app === "escuela") this.validarPermisoRuta();
        this.escuelaTienePermiso = true;
        this.getAreas();
      }
    },
  },
  mounted() {
    if (this.sessionToken) {
      if (this.app === "escuela" && this.menu.length > 0)
        this.validarPermisoRuta();
      this.escuelaTienePermiso = true;
      this.getAreas();
    }
  },

  data() {
    return {
      loading: false,
      escuelaTienePermiso: false,
      headers: [
        {
          text: "",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "30px",
        },

        {
          text: "Nombre",
          value: "video",
          sortable: false,
          filterable: false,
        },
        {
          text: "Usuario",
          value: "usuario",
          sortable: false,
          filterable: false,
          align: "center",
        },
        {
          text: "Tiempo",
          value: "tiempo",
          sortable: false,
          filterable: false,
          width: "200px",
          align: "center",
        },

        {
          text: "",
          value: "edicion",
          sortable: false,
          filterable: false,
          width: "50px",
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },
      areas: [],
      areaSeleccionada: "",
      abrirEditorArea: false,
      editarArea: false,
      areaParaModificar: null,
      videos: [],
      subareas: [],
      subareaSeleccionada: "",
      abrirEditorSubarea: false,
      subareaParaModificar: null,
      abrirEditorVideo: false,
      mover: false,
      videoSeleccionado: null,
    };
  },
  methods: {
    updateVideos() {
      this.limpiarPagination();
      this.getVideos();
    },

    validarPermisoRuta() {
      const menuEvaluacion = this.menu.find((x) => x.title === "Evaluación");
      if (menuEvaluacion) {
        const itemIndex = menuEvaluacion.items.findIndex(
          (x) => x.title === "Videos"
        );
        if (itemIndex == -1) {
          this.$router.push("/dashboard");
        } else this.escuelaTienePermiso = true;
      }
    },

    modificarAreaSeleccionada() {
      this.areaParaModificar = this.areas.filter(
        (x) => x._id === this.areaSeleccionada
      )[0];
      this.abrirEditorArea = true;
      this.editarArea = true;
    },
    modificarSubareaSeleccionada() {
      this.subareaParaModificar = this.subareas.filter(
        (x) => x._id === this.subareaSeleccionada
      )[0];
      this.abrirEditorSubarea = true;
    },

    cancelarEditarArea() {
      this.abrirEditorArea = false;
      this.editarArea = false;
      this.areaParaModificar = null;
    },
    cancelarEditarsubarea() {
      this.abrirEditorSubarea = false;
      this.subareaParaModificar = null;
    },

    async getAreas() {
      this.loading = true;
      try {
        const serverResponse = await getAreasService();
        this.loading = false;

        this.$validateResponse(serverResponse);

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          // this.$router.push('/dashboard')
        } else if (serverResponse.areas.length) {
          this.areas = serverResponse.areas;
          this.areaSeleccionada = serverResponse.areas[0]._id;
          this.getSubareas(this.areaSeleccionada);
        }
      } catch (error) {
        //console.log(error)
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async getSubareas(areaSeleccionada) {
      this.loading = true;
      try {
        const serverResponse = await getSubareasService(areaSeleccionada);
        this.loading = false;

        this.$validateResponse(serverResponse);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (serverResponse.subareas.length > 0) {
            this.subareas = serverResponse.subareas;
            this.subareaSeleccionada = this.subareas[0]._id;

            this.getVideos();
          } else {
            this.subareas = [];
            this.videos = [];
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async getVideos() {
      this.loading = true;
      try {
        const serverResponse = await getVideosService(
          this.areaSeleccionada,
          this.subareaSeleccionada,
          this.pagination.itemsPerPage,
          this.pagination.page
        );
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.videos = [];
          let numero = 1;
          for (const video of serverResponse.videos) {
            let item = {
              numero: numero++,
              tiempo: video.time,
              tipo: video.type,
              usuario: video.usuario,
              video: {
                nombre: video.nombre,
                area: video.area,
                subarea: video.subarea,
                _id: video._id,
              },
              edicion: {
                _id: video._id,
              },
            };
            this.videos.push(item);
          }
          this.pagination.itemsLength = serverResponse.total;
          this.pagination.pageStop = serverResponse.pageStop;
          this.pagination.pageStart = serverResponse.pageStart;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    areaGuardada(nuevaArea) {
      this.limpiarPagination();
      const areaExiste = this.areas.filter((e) => e._id === nuevaArea._id)[0];

      if (areaExiste) {
        let index = this.areas.findIndex((x) => x._id === nuevaArea._id);
        this.areas.splice(index, 1, nuevaArea);
      } else {
        this.areas.push(nuevaArea);
        this.areaSeleccionada = nuevaArea._id;
        this.getSubareas(this.areaSeleccionada);
      }

      this.abrirEditorArea = false;
      this.editarArea = false;
      this.areaParaModificar = null;
    },
    subareaGuardada(nuevaArea) {
      this.limpiarPagination();
      const areaExiste = this.subareas.filter(
        (e) => e._id === nuevaArea._id
      )[0];
      if (areaExiste) {
        let index = this.subareas.findIndex((x) => x._id === nuevaArea._id);
        this.subareas.splice(index, 1, nuevaArea);
      } else {
        this.subareas.push(nuevaArea);
        this.subareaSeleccionada = nuevaArea._id;
        this.getVideos(this.subareaSeleccionada);
      }
      this.abrirEditorSubarea = false;
      this.subareaParaModificar = null;
    },
    updatePageTable(value) {
      this.pagination.pageCount += 1;
      this.pagination.page = value.page;
      this.getVideos();
    },

    paginaCambio(pagina) {
      this.pagination.page = pagina;
      this.getVideos();
    },
    limpiarPagination() {
      this.pagination.page = 1;
      this.pagination.pageStart = 0;
      this.pagination.pageStop = 0;

      this.pagination.itemsLength = 0;
    },
    moverVideo(video) {
      this.mover = true;
      this.videoSeleccionado = video;
    },
  },
};
</script>

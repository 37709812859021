import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":600,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Mover video")]),_c(VSpacer)],1),_c('br'),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c('selector-areas',{attrs:{"areas":_vm.areas},on:{"change":function($event){return _vm.getSubareas(_vm.areaSeleccionada)}},model:{value:(_vm.areaSeleccionada),callback:function ($$v) {_vm.areaSeleccionada=$$v},expression:"areaSeleccionada"}})],1)],1),_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c('div',{staticClass:"d-flex"},[_c('selector-areas',{attrs:{"areas":_vm.subareas,"etiqueta":'Subárea'},model:{value:(_vm.subareaSeleccionada),callback:function ($$v) {_vm.subareaSeleccionada=$$v},expression:"subareaSeleccionada"}})],1)])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","small":"","loading":_vm.loading,"disabled":_vm.subareaSeleccionada == '' || _vm.loading || _vm.subareaSeleccionada==_vm.subarea},on:{"click":function($event){return _vm.moverVideo()}}},[_vm._v(" Mover")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Agregar video")]),_c(VSpacer)],1),_c('br'),_c(VContainer,[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Nombre","disabled":_vm.loading,"error-messages":_vm.errorsNombre.nombre},on:{"input":function($event){return _vm.$v.nombre.$touch()},"blur":function($event){return _vm.$v.nombre.$touch()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c(VFileInput,{attrs:{"placeholder":"Seleccionar video","prepend-icon":"mdi-video","outlined":"","dense":"","error-messages":_vm.error,"disabled":_vm.loading},on:{"change":_vm.validarArchivo},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c(VChip,{attrs:{"small":"","label":""}},[_vm._v(_vm._s(text))])]}}]),model:{value:(_vm.archivo),callback:function ($$v) {_vm.archivo=$$v},expression:"archivo"}}),(!_vm.terminado)?_c(VCol,{staticClass:"subtitle-1 text-center"},[_vm._v("Subiendo video "+_vm._s(_vm.progreso + " %"))]):_vm._e(),(_vm.procesando)?_c(VCol,{staticClass:"subtitle-1 text-center"},[_vm._v("Procesando video..")]):_vm._e(),(!_vm.terminado)?_c(VProgressLinear,{attrs:{"value":_vm.progreso,"max":100,"show-progress":"","animate":"","rounded":"","height":"12"}}):_vm._e(),(_vm.procesando)?_c(VProgressLinear,{attrs:{"value":_vm.progreso,"max":100,"indeterminate":"","animate":"","rounded":"","height":"12"}}):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"primary","small":"","loading":_vm.loading,"disabled":!_vm.archivo || !_vm.esValido || _vm.nombre==''},on:{"click":function($event){return _vm.calcularTiempo()}}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import {
  getServerRequest,
  postServerRequest,
  patchServerRequest,
  putServerRequest,
  deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../store/store";

const httpURL = store.getters.httpURL;

export const getAreasService= async () => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/videos/areas/listado`;
  return await getServerRequest(url, config);
};

export const crearAreaVideoService = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token }};
  const url = `${httpURL}/videos/area`;
  return await postServerRequest(url, data, config);
}

export const modificarAreaVideoService = async (idArea, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token }};
  const url = `${httpURL}/videos/area/${idArea}`;
  return await patchServerRequest(url, data, config);
}

export const getSubareasService= async (idArea) => {
  
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/videos/${idArea}/subareas/listado`;
  return await getServerRequest(url, config);
};

export const crearSubareaVideoService = async (idArea, data) => {
  
  const token = store.getters.sessionToken;
  const config = { headers: { token }};
  const url = `${httpURL}/videos/${idArea}/subarea`;
  return await postServerRequest(url, data, config);
}

export const modificarSubareaVideoService = async (idArea, idSubArea, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token }};
  const url = `${httpURL}/videos/${idArea}/${idSubArea}`;
  return await patchServerRequest(url, data, config);
}

export const getVideosService= async (idArea, idSubarea, registros, pagina) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/videos/listado/${idArea}/${idSubarea}/${registros}/${pagina}`;
  return await getServerRequest(url, config);
};

export const agregarVideoService = async (data, uploadHandler, ids) =>{
  const token = store.getters.sessionToken;
  const config = {
      headers: { token , 'Content-Type': 'multipart/form-data' },
      ...uploadHandler
  };
  const url = `${httpURL}/videos/${ids.idArea}/${ids.idSubarea}/video`;
  return await postServerRequest(url, data, config);
}

export const getVideoService= async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/videos/video/${data.area}/${data.subarea}/${data.idVideo}`;
  return await getServerRequest(url, config);
};

export const modificarDescripcionVideoService = async (data, idVideo)=> {
  
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/video/descripcion/${idVideo}`;
  return await patchServerRequest(url, data, config);
}

export const modificarNombreVideoService = async (data, idVideo)=> {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/video/nombre/${idVideo}`;
  return await patchServerRequest(url, data, config);
}

export const moverVideoService = async (area, subarea, video)=> {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/video/mover/area/${area}/${subarea}/${video}`;
  return await patchServerRequest(url, {}, config);
} 